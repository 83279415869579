import React, { useState } from 'react';
import { Checkbox, PropertyFilter, SpaceBetween } from '@amzn/awsui-components-react';
import { PropertyFilterProps, Query } from '@amzn/awsui-components-react/polaris/property-filter/interfaces';
import { PropertyFilterOperator, PropertyFilterToken } from '@amzn/awsui-collection-hooks';
import { FilterInfo, FilterInfoOperators, PolicyFilterProps, PolicyFilterTypes } from '.';



export interface DerFilterFormProps {
    field: PolicyFilterTypes,
    filters: string,
    onUpdate: (value: string) => void
} 

const i18nSettings = {
    filteringAriaLabel: "your choice",
    dismissAriaLabel: "Dismiss",
    filteringPlaceholder:
        "Filter by value",
    groupValuesText: "Values",
    groupPropertiesText: "Properties",
    operatorsText: "Operators",
    operationAndText: "and",
    operationOrText: "or",
    operatorLessText: "Less than",
    operatorLessOrEqualText: "Less than or equal",
    operatorGreaterText: "Greater than",
    operatorGreaterOrEqualText:
        "Greater than or equal",
    operatorContainsText: "Like",
    operatorDoesNotContainText: "Is not Like",
    operatorEqualsText: "Equals",
    operatorDoesNotEqualText: "Does not equal",
    editTokenHeader: "Edit filter",
    propertyText: "Property",
    operatorText: "Operator",
    valueText: "Value",
    cancelActionText: "Cancel",
    applyActionText: "Apply",
    allPropertiesLabel: "All properties",
    tokenLimitShowMore: "Show more",
    tokenLimitShowFewer: "Show fewer",
    clearFiltersText: "Clear filters",
    removeTokenButtonAriaLabel: token =>
        `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
    enteredTextLabel: text => `Use: "${text}"`
};

const Operators = [
    ["=", "eq"],
    ["!=", "noteq"],
    [":", "like"],
    ["!:", "notlike"]
] as [PropertyFilterOperator, FilterInfoOperators][];

// "=" -> "eq"
const OperatorMap = new Map<PropertyFilterOperator, FilterInfoOperators>(Operators);

// "eq" -> "="
const ReverseOperatorMap = new Map<FilterInfoOperators, PropertyFilterOperator>(Operators.map(x => [x[1], x[0]]));

const filteringProperties: {[key in PolicyFilterTypes]: PropertyFilterProps.FilteringProperty} = {
    "action": {
        key: "action",
        operators: [":", "!:", "=", "!="],
        propertyLabel: "Action",
        groupValuesLabel: "Action values"
    },
    "resource": {
        key: "resource",
        operators: [":", "!:", "=", "!="],
        propertyLabel: "Resource",
        groupValuesLabel: "Resource values"
    },
    "condition": {
        key: "condition",
        operators: [":", "!:", "=", "!="],
        propertyLabel: "Condition",
        groupValuesLabel: "Condition values"
    },
    "account": {
        key: "account",
        operators: [":", "!:", "=", "!="],
        propertyLabel: "Account",
        groupValuesLabel: "Account values"
    },
    "organization_id": {
        key: "organization_id",
        operators: [":", "!:", "=", "!="],
        propertyLabel: "OrganizationId",
        groupValuesLabel: "OrganizationId values"
    },
    "principal": {
        key: "principal",
        operators: [":", "!:", "=", "!="],
        propertyLabel: "Policy Principal",
        groupValuesLabel: "Policy Principal values"
    },
    "service_principal": {
        key: "service_principal",
        operators: [":", "!:", "=", "!="],
        propertyLabel: "Service Principal",
        groupValuesLabel: "Service Principal values"
    }
}

export default function DerFilterForm({field, filters, ...props }: DerFilterFormProps): JSX.Element {
    const [tokens, _caseSensitive] = buildTokensFromFilters(filters);
    const [query, setQuery] = useState<Query>(tokens);   
    const [caseSensitive, setCaseSensitive] = useState<boolean>(_caseSensitive);

    function updateQuery(detail: PropertyFilterProps.Query): void {
        setQuery(detail);
        props.onUpdate(buildFiltersFromTokens(detail, caseSensitive));
    }

    function buildTokensFromFilters(filters: string): [PropertyFilterProps.Query, boolean] {
        try {
            const policyFilter = JSON.parse(filters) as PolicyFilterProps;
            return [{
                operation: policyFilter.operation,
                tokens: policyFilter.filters.map((f) => {return {propertyKey: f.property, operator: ReverseOperatorMap.get(f.operator)!, value: f.value};})
            }, policyFilter.caseSensitive]
        }
        catch {
            // Support previous version values
            // TODO: Deprecate
            const filterList = filters.split("\n");
    
            // Handle empty/initial case
            if (filterList.length == 1 && filterList[0] == "") {
                return [{
                    tokens: [],
                    operation: "or"
                }, false];
            }
    
            return [{tokens: filterList.map((f) => {
                                return {propertyKey: field, operator: ":", value: f};  
                            }),
                    operation: "or"}, false];
        }
    }

    function buildFiltersFromTokens(query: PropertyFilterProps.Query, caseSensitive: boolean): string {
        const filters = query.tokens.map<FilterInfo>((t) => {
            return {
                value: t.value,
                property: t.propertyKey,
                operator: OperatorMap.get(t.operator)
            } as FilterInfo;
        });

        const result: PolicyFilterProps = {
            caseSensitive: caseSensitive,
            operation: query.operation,
            filters: filters
        };
        return JSON.stringify(result);
    }

    function updateCaseSensitive(checked: boolean): void {
        setCaseSensitive(checked);
        props.onUpdate(buildFiltersFromTokens(query, checked));
    }

    return (
        <SpaceBetween size="xs" direction="horizontal">
            <PropertyFilter
                id={field + "-filter"}
                query={query}
                onChange={({detail}) => updateQuery(detail)}
                expandToViewport
                filteringProperties={[
                    filteringProperties[field]
                ]}
                disableFreeTextFiltering
                i18nStrings={i18nSettings}
            />
            <Checkbox id={field + "-case-sensitive"} checked={caseSensitive} onChange={({detail}) => updateCaseSensitive(detail.checked)}>
                Case Sensitive
            </Checkbox>
        </SpaceBetween>
    );
}